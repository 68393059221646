<template>
  <div>
    <div v-if="path == 'h5' && openModal" class="modal_mask"></div>
    <div class="showModal" v-if="path == 'h5' && openModal">
      <div v-if="path == 'h5' && openModal" class="close" @click="linkClick">
        <img src="../assets/close.png" alt="" />
      </div>
      <iframe
        v-if="iframeData"
        class="iframe"
        :src="iframeData"
        frameborder="0"
      ></iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: "linkItem",
  props: {
    path: String,
    iframeData: String,
    isShow: Boolean,
  },
  data() {
    return {
      openModal: false,
    };
  },
  methods: {
    iframeEvent(url) {
      // eslint-disable-next-line vue/no-mutating-props
      this.iframeData = url;
    },
    linkClick() {
      this.openModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.showModal {
  position: fixed;
  z-index: 100000;
  top: 2%;
  left: 50%;
  margin-left: -200px;
  width: 400px;
  height: 85%;
  background: url("../assets/phone.png") 0 0 no-repeat;
  background-size: 100% 100%;
  padding: 69px 16px 30px 15px;
  .iframe {
    width: 100%;
    height: 100%;
    border-radius: 0 0 30px 30px;
  }
  .close {
    position: absolute;
    top: 1%;
    right: -15%;
    z-index: 100001;
    cursor: pointer;
    img {
      display: block;
      width: 40px;
      height: 40px;
    }
  }
}
.modal_mask {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(55, 55, 55, 0.8);
  height: 100%;
  z-index: 10000;
}
</style>
