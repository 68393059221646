<template>
  <div v-show="visible" class="modalBox" :style="{ background: getBgc() }">
    <div class="mtitle">{{ paramData.name }}</div>
    <div class="content">{{ paramData.info }}</div>
    <div class="link" @click="goTarget(paramData.href)">点击进入></div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    paramData: {
      type: Object,
      default: null,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    goTarget() {
      this.$emit("showModal");
    },
    getBgc() {
      const img = require(`@/assets/modalBg/${this.paramData.name}.png`);
      return `url(${img}) 0 0/100% 100% no-repeat`;
    },
  },
};
</script>
<style lang='scss'>
.modalBox {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  width: 43%;
  height: 35%;
  opacity: 1;
  border-radius: 8px;
  color: #fff;
  padding: 40px;
  .mtitle {
    margin-top: 40px;
    font-size: 24px;
    font-weight: 700;
  }
  .content {
    margin-top: 30px;
    font-size: 18px;
  }
  .link {
    font-size: 16px;
    font-weight: 700;
    text-align: right;
    margin-top: 120px;
    cursor: pointer;
  }
}
</style>
