<template>
  <div class="wrapper" @click="handleClick">
    <div class="title">
      <img src="./assets/title.png" alt="" />
    </div>
    <div class="middleBox">
      <div class="left">
        <div class="title">产业监测平台</div>
        <div class="box">
          <div
            v-for="(item, index) in monitor.slice(0, 6)"
            :key="index"
            class="monitorItem"
            :style="{ background: getImg(item.name) }"
            @click="itemModal(item, $event)"
            @mousemove="inCircle(index)"
          >
            <div class="info" v-show="curIndex == index">
              <img :id="item.name" src="./assets/注释.png" alt="" />
            </div>
            <div class="text">{{ item.name }}</div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="title">产业服务平台</div>
        <div class="box">
          <div
            v-for="(item, index) in monitor.slice(6, 11)"
            :key="index"
            :style="{ background: getImg(item.name) }"
            :class="['surviceItem', { longItem: item.name == '供应链交易平台' }]"
            @click="itemModal(item, $event)"
            @mousemove="inCircle(index + 6)"
          >
            <div class="info" v-show="curIndex == index + 6">
              <img :id="item.name" src="./assets/注释.png" alt="" />
            </div>
            <div class="text">{{ item.name }}</div>
          </div>
        </div>
      </div>
    </div>
    <Modal :paramData="curModal" :visible="visible" @showModal="showModal" />
    <LinkItem ref="linkItem" :path="linkPath" :iframeData="iframeUrl" />
  </div>
</template>

<script>
import Modal from "./components/modal.vue";
import LinkItem from "./components/linkItem.vue";
export default {
  name: "App",
  data() {
    return {
      curIndex: 0,
      monitor: [
        {
          name: "一企一档",
          href: "https://bj-sale.hsbrain.com/?showdemo=1#/searchdetail",
          info:
            "一企一档以企业为核心，建设企业数字档案，通过不同指标绘制企业画像，体现企业发展全貌，展示企业画像、企业对标、企业信息全维度企业发展情况，帮助领导、产业管理人员实时了解企业发展情况，并识别企业发展优劣，便于对企业进行针对性服务。",
        },
        {
          name: "企业动向",
          href: "https://bj-sale.hsbrain.com/?showdemo=1#/monitoring",
          info:
            "对集团重点关注的企业进行全方位事件监测，帮助领导及企业管理人员实时了解企业发展动态，识别企业发展重大成果及风险。监测及预警内容包括企业工商变更、高管动向、产业外溢、经营风险、法律风险等信息。",
        },
        {
          name: "企业榜单",
          href:
            "https://bj-sale.hsbrain.com/?showdemo=1#/monitoring/companiesList",
          info:
            "首先对集团重点关注企业进行企业发展阶段分类包括龙头企业、高成长企业、潜力企业，并通过企业榜单模型对不同类型企业进行排行，帮助领导及企业管理人员了解企业在北京产业中的地位，并帮助用户识别优秀企业。",
        },
        {
          name: "需求监测",
          href: "https://bj-sale.hsbrain.com/?showdemo=1#/monitoring/demand",
          info:
            "通过企业采集工具帮助企业实时上报企业发展过程中的需求包括空间需求、政策需求、人才需求、资金需求、研发服务需求等，在此基础上整合集团内外部资源为企业提供服务资源智能匹配，帮助企业更好的获取资源。",
        },
        {
          name: "外迁监测",
          href:
            "https://bj-sale.hsbrain.com/?showdemo=1#/monitoring/mainOutSourcing",
          info:
            "构建企业外迁预测模型并结合线下调研识别有外迁风险的企业，并对外迁风险进行分级，并对外迁企业所属领域进行分析帮助用户实时发现企业外迁风险。",
        },
        {
          name: "重点领域监测",
          href:
            "https://bj-sale.hsbrain.com/?showdemo=1#/monitoring/mainAreasManage",
          info:
            "从产业链切入，了解重点监测领域的产业链结构，并根据产业评估模型，发现国内产业瓶颈及北京市的产业优势。针对产业链环节上的要素动态实施监测。通过产业图谱，对瓶颈及优势的实体关系进一步挖掘，发现重点企业及人才，通过相应的扶持手段，实现产业链的强链和补链。",
        },
        {
          name: "政策服务",
          href: "https://isp-demo1.hsbrain.com/",
          info:
            "汇聚全国、北京各级产业政策（科技、发改、卫计、药监、人社等政策），根据企业发展阶段和当前需求智能推送科技资源、政策资讯和公共服务等信息，为企业提供关联政策解读等精准服务。",
        },
        {
          name: "人才服务",
          href: "https://isp-demo4.hsbrain.com/",
          info:
            "整合北京市专家资源，导入区域重点发展细分领域全国、全球科研、经营管理领军人才资源，建设北京医药健康专家库，帮助企业提供高端人才对接服务。主要包括本地人才资源整合、全国高端人才资源导入、企业人才需求发布、高端人才动向、企业高端人才精准对接。",
        },
        {
          name: "临床服务",
          href: "https://isp-demo5.hsbrain.com/",
          info:
            "数据化国内GCP临床试验机构及临床专家，及重点CRO、CDMO资源帮助企业进行临床试验资源检索、详情查看及资源对接。解决企业产品研发过程中资源对接难题。",
        },
        {
          name: "空间服务",
          href: "https://isp-demo2.hsbrain.com/",
          info:
            "针对企业创新转化及扩大再生产对落地空间的需求，整合园区空间资源，帮助企业提供精准选址服务，让企业更方便、准确了解空间基本情况、产业集聚情况、园区扶持政策、园区服务资源等。",
        },
        {
          name: "供应链交易平台",
          href: "https://bioisp.hsmap.com/#/platform?from=demoChangping",
          info:
            "包括数字化智慧产业园区运营平台和B2B资源服务和交易平台，旨在打通政企屏障，提供一站式服务，打造开放的创新因素供需智能生态链，让数字化赋能创新全流程。",
        },
      ],
      curModal: {},
      visible: false,
      iframeUrl: null,
      linkPath: "h5",
      b2bShow: false,
    };
  },
  components: {
    Modal,
    LinkItem,
  },
  methods: {
    getImg(name) {
      const img = require(`@/assets/${name}.png`);
      return `url(${img}) 0 0/100% 100% no-repeat`;
    },
    itemModal(params, e) {
      if (e.target.id) {
        return;
      }
      if (
        params.name == "政策服务" ||
        params.name == "人才服务" ||
        params.name == "临床服务" ||
        params.name == "空间服务"
      ) {
        this.$refs.linkItem.openModal = true;
        this.iframeUrl = params.href;
      } else {
        window.location.href = params.href;
      }
    },
    inCircle(Index) {
      this.curIndex = Index;
    },
    handleClick(e) {
      this.visible = false;
      setTimeout(() => {
        const vlidName = this.monitor.map((item) => {
          return item.name;
        });
        if (vlidName.includes(e.target.id)) {
          this.visible = true;
          this.monitor.map((item) => {
            if (item.name == e.target.id) {
              this.curModal = item;
            }
          });
        } else {
          this.visible = false;
        }
      }, 400);
    },
    showModal() {
      if (
        this.curModal.name == "政策服务" ||
        this.curModal.name == "人才服务" ||
        this.curModal.name == "临床服务" ||
        this.curModal.name == "空间服务"
      ) {
        this.$refs.linkItem.openModal = true;
        this.iframeUrl = this.curModal.href;
      } else {
        window.location.href = this.curModal.href;
      }
    },
  },
};
</script>

<style lang='scss'>
//别加scopede
html,
body,
#app,
.wrapper {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  min-width: 1366px;
  background: url("assets/bj.png") no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
  position: relative;
}
.title {
  width: 100%;
  line-height: 28px;
  margin-top: 3%;
  display: flex;
  justify-content: center;
  img {
    display: block;
    height: 66px;
  }
}
.middleBox {
  width: 100%;
  display: flex;
  .left,
  .right {
    width: 50%;
    padding: 5% 3% 0;
    color: #fff;
    .title {
      font-size: 20px;
      font-family: MicrosoftYaHeiUI, MicrosoftYaHeiUI-Bold;
      font-weight: 700;
      margin-bottom: 26px;
    }
    .box {
      text-align: center;
    }
    .monitorItem,
    .surviceItem {
      width: 26%;
      min-width: 160px;
      text-align: center;
      height: 215px;
      display: inline-block;
      margin: 10px;
      position: relative;
      font-weight: 700;
      .info {
        position: absolute;
        width: 20px;
        height: 20px;
        top: 10px;
        right: 10px;
        cursor: pointer;
        img {
          display: block;
          width: 16px;
          height: 16px;
        }
      }
      .text {
        position: absolute;
        bottom: 20px;
        right: 0;
        left: 0;
        margin: auto;
      }
    }
    .monitorItem:hover {
      transform: scale(1.1);
    }
    .surviceItem:hover {
      transform: scale(1.1);
    }
  }
  .right {
    .longItem {
      width: 54.5%;
      min-width: 340px;
    }
  }
}
</style>
